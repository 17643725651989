import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import { isApiKeyEnabled } from 'utilities/authUtilities';

import HomePage from './pages/HomePage';
import RepoPage from 'pages/RepoPage';
import TagPage from 'pages/TagPage';
import ExplorePage from 'pages/ExplorePage';
import UserManagementPage from 'pages/UserManagementPage';

import './App.css';

function App() {
  return (
    <div className="App" data-testid="app-container">
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/explore" element={<ExplorePage />} />
          <Route path="/image/:name" element={<RepoPage />} />
          <Route path="/image/:reponame/tag/:tag" element={<TagPage />} />
          {isApiKeyEnabled() && <Route path="/user/apikey" element={<UserManagementPage />} />}
          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
